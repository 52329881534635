
import React, { useState, useEffect } from 'react'
import pavapark from './img/pavapark.png'
import quartAjuntament from './img/quartAjuntament.png'
import parkingbliblioteca from './img/parkingBiblioteca.png'
import { accessparkStock } from './services/GetInfoAccesspark'
import './App.css' 

export const App = () => {
const [accesspark, setAccesspark] = useState([])
const [loading, setloading] = useState(true)
  useEffect(() => {
    accessparkStock()
        .then((data) => {
          setAccesspark(data)
          setloading(false)
        })
        .catch((err) => {
           console.log(err.message)
        })
    intervalCall()
  },[])

  const intervalCall = async () => {
    setInterval(() => {
      accessparkStock()
        .then((data) => setAccesspark(data))
        .catch((err) => {
           console.log(err.message)
        })
      }, 60000)
      /*hace la llamada cada minuto*/
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="column" id="first"><img src={parkingbliblioteca} id="parkingbliblioteca" alt="Ayuntamiento Quart de Poblet" /></div>
        <div className="column" id="center"></div>
        <div className="column"><img src={quartAjuntament} id="quartAjuntament" alt="Ayuntamiento Quart de Poblet" /></div>                     
      </header>
      <div className="App-body">
        <img src={pavapark} className="App-logo" alt="logo" />
        { loading ? <></> : (accesspark.status === "Libre" ? <p id='parking_free'>LIBRE</p>:<p id='parking_complete'>COMPLETO</p>) }
      </div>
    </div>
  )
}



export default class Config {
    static ACCESPARK_URL
    static ACCESPARK_USERNAME
    static ACCESPARK_PASSWORD
    static ACCESPARK_ZONE_ID
    static ACCESPARK_TOKEN_SECRET
};

Config.ACCESPARK_URL = process.env.REACT_APP_ACCESPARK_URL
Config.ACCESPARK_USERNAME = process.env.REACT_APP_ACCESPARK_USERNAME
Config.ACCESPARK_PASSWORD = process.env.REACT_APP_ACCESPARK_PASSWORD
Config.ACCESPARK_ZONE_ID = process.env.REACT_APP_ACCESPARK_ZONE_ID
Config.ACCESPARK_TOKEN_SECRET = process.env.REACT_APP_ACCESPARK_TOKEN_SECRET


import Config from "../Config"
import axios from "axios"
import qs from "qs"
import CryptoJS from "crypto-js"

const url = Config.ACCESPARK_URL
const username = Config.ACCESPARK_USERNAME
const password = Config.ACCESPARK_PASSWORD
const id = Config.ACCESPARK_ZONE_ID
const access_token_secret = Config.ACCESPARK_TOKEN_SECRET

const user = qs.stringify({
    'username': username,
    'password': password
})

async function getToken() {
    const hash = CryptoJS.AES.encrypt(user, access_token_secret).toString()
    const configToken = {
        method: 'post',
        url: `${url}/api/loginhash`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: qs.stringify({ 'hash': hash })
    }
    return axios(configToken)
        .then((response) => qs.parse(qs.stringify(response.data)).token)
        .catch(function (error) {
            console.log(error)
        })
}

async function getStock(token) {
    const configStock = {
        method: 'post',
        url: `${url}/api/zone/${id}/getstock`,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${token}`
        }
    }
    const response = await axios(configStock)
    return response
}

export const accessparkStock = async () => {
    try {
        const token = await getToken()
        const ejem = await getStock(token)
        return ejem.data
    } catch (error) {
        console.error(error)
    }
}
